@import "@/styles/globals.scss";
@font-face {
  font-family: 'Sohne';
  src: url('https://rockhall.com/wp-content/uploads/2024/02/Sohne-Extraleicht.woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Sohne';
  src: url('https://rockhall.com/wp-content/uploads/2024/02/Sohne-ExtraleichtKursiv.woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Sohne';
  src: url('https://rockhall.com/wp-content/uploads/2024/02/Sohne-Kraftig.woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sohne';
  src: url('https://rockhall.com/wp-content/uploads/2024/02/Sohne-KraftigKursiv.woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Sohne';
  src: url('https://rockhall.com/wp-content/uploads/2024/02/Sohne-Halbfett.woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sohne';
  src: url('https://rockhall.com/wp-content/uploads/2024/02/Sohne-HalbfettKursiv.woff2');
  font-weight: 600;
  font-style: italic;
}
